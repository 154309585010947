<template>
  <div>
    <div class="breadCurmb mt-3">
      <label>
        <router-link to="/driver/home">{{ $t("Area") }}</router-link>
      </label>
      <span>/</span>
      <label active>{{
        $route.name == "driverEvents2"
          ? "عرض جميع المشتركين"
          : "عرض المشتركين بالنسبة للحي"
      }}</label>
    </div>

    <div>
      <el-card v-if="details" style="border: 1px solid blue; margin-top: 15px">
        <v-container fluid>
          <v-row>
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
              <h3>تم التسليم : {{ details.deliver }}</h3>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
              <h3>لم يتم التسليم : {{ details.not_deliver }}</h3>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
              <h3> المتبقي : {{ table.items2.length }}</h3>
            </v-col>
            <!-- <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
              <h3>العدد: {{ details.count_subscription }} مشترك</h3>
            </v-col> -->
          </v-row>
        </v-container>
      </el-card>
    </div>

    <div v-if="$route.name == 'driverEvents2' && false">
      <!-- filters -->

      <v-container fluid>
        <v-row>
          <v-col class="pt-3" :cols="$vuetify.breakpoint.mdAndUp ? '2' : '12'">
            <div>
              <label>{{ $t("Neighborhood") }}</label>
              <v-autocomplete
                style="width: 200px"
                class="d-block my-2"
                name="neighborhood_id"
                data-vv-scope="addEditValidation2"
                v-validate="''"
                :data-vv-as="$t('Neighborhood')"
                hide-details
                dense
                outlined
                v-model="filters.neighborhood_id"
                item-value="id"
                item-text="name"
                :items="branchesList"
                clearable
              ></v-autocomplete>
            </div>
          </v-col>
          <v-col class="pt-10" :cols="$vuetify.breakpoint.mdAndUp ? '2' : '12'">
            <el-button
              @click="filterData"
              style="width: 120px"
              type="success"
              >{{ $t("Filter") }}</el-button
            >
          </v-col>
        </v-row>
      </v-container>
    </div>

    <h2 class="my-2" 
    :style="$vuetify.breakpoint.mdAndUp?'': 'text-align:center;'" 
    style="background:#ff8d8d;padding:15px;color:#000;border-radius:10px;">
      {{
        $route.name == "driverEvents2"
          ? "عرض المشتركين لم يتم التسليم"
          : "عرض المشتركين بالنسبة للحي لم يتم التسليم"
      }}
    </h2>

    <!-- Start DataTable -->
    <v-data-table v-if="$vuetify.breakpoint.mdAndUp "
      :headers="table.headers"
      :items="table.items2"
      :loading="table.loading"
      hide-default-footer
      :items-per-page="table.items2.length"
      class="elevation-1"
      :loading-text="$t('LoadData')"
    >
      <template v-slot:no-data>
        <v-alert class="mt-5 white-clr" color="third">
          {{ $t("NoData") }}
        </v-alert>
      </template>

      <template v-slot:item.customer_attachments="{ item }">
        <img
          @click="openGallery(item.customer_attachments, item.home_number)"
          v-if="item.customer_attachments"
          style="border-radius: 50%; margin: 0 5px; cursor: pointer"
          class="mr-2 mt-2"
          width="50"
          height="50"
          :src="
            item.customer_attachments.length > 0
              ? item.customer_attachments[item.customer_attachments.length - 1]
              : '/assets/no-img.png'
          "
          alt=""
        />
      </template>
      <template v-slot:item.branch="{ item }">
        <span>{{ item.branch }} - {{ item.company }}</span>
      </template>

      <template v-slot:item.customer_name="{ item }">
        <div style="display: flex; align-items: center;">
  <img  style="display: inline-block;" width="40" v-if="item.customer_is_new == 1" src="/assets/new_icon.png" alt="">
  <span
    @click="openGallery(item.customer_attachments, item.home_number)"
    :style="
      item.customer_attachments.length > 0
        ? 'color: blue; cursor: pointer; font-weight: bold;'
        : ''
    "
  >
    {{ item.customer_name }}
  </span>
</div>

      </template>

      <template v-slot:item.driver_delivery="{ item, index }">
        <div>
          <span v-if="item.driver_delivery == 0">
            <v-icon
              class="my-0 mr-2 ml-2"
              style="font-size: 28px; color: purple !important"
              dense
              >mdi-circle-outline</v-icon
            >
          </span>
          <span v-if="item.driver_delivery == 1" class="checkbox-marked">
            <v-icon class="my-0 mr-2 ml-2" style="font-size: 28px" dense
              >mdi-checkbox-marked-circle-outline</v-icon
            >
          </span>
          <span v-if="item.driver_delivery == 2" class="checkbox-unmarked">
            <v-icon
              class="my-0 mr-2 ml-2"
              style="font-size: 28px; color: red"
              dense
              >mdi-close-circle-outline</v-icon
            >
          </span>

          <el-button
            v-if="details"
            style="padding: 2px"
            :disabled="!details.deliver_btn || item.driver_delivery > 0"
            @click="
              detailsItem = item;
              delivarModal = true;
            "
            class="mx-2"
            type="success"
          >
            <v-icon
              class="my-0"
              style="font-size: 28px; color: #fff !important"
              dense
              >mdi-checkbox-marked-circle-outline</v-icon
            >
          </el-button>

          <el-button
            style="padding: 2px"
            v-if="details"
            :disabled="!details.deliver_btn || item.driver_delivery > 0"
            @click="openDeleteDelivar(item)"
            class="mx-2"
            type="danger"
          >
            <v-icon
              class="my-0"
              style="font-size: 28px; color: #fff !important"
              dense
              >mdi-close-circle-outline</v-icon
            >
          </el-button>
        </div>
      </template>

      <template v-slot:item.actions="{ item, index }">
        <v-row>
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-col :cols="'12'">
              <v-file-input
                style="display: inline-block !important; position: relative;margin: 0 15px;position:relative;top:5px;"
                dense
                hide-input
                prepend-icon="mdi-camera"
                accept="image/*"
                multiple
                @change="uploadImage($event, item.customer_id)"
              ></v-file-input>

              <v-icon
                dense
                @click="changePeriod(item.subscription_detail_id, item.customer_name)"
                style="color: red !important; cursor: pointer; font-size: 22px;margin: 0 15px;"
                >mdi-repeat</v-icon
              >

              <v-icon
              dense
              @click="goWatsapp(item.customer_mobile)"
              style="color: green !important; cursor: pointer; font-size: 24px;margin: 0 15px;"
              >mdi-whatsapp</v-icon
              >

              <v-icon
                @click="goToLink(item)"
                style="font-size: 28px; cursor: pointer; position: relative;margin: 0 15px;"
                :style="
                  item.link_map
                    ? 'color:green !important;cursor: pointer;'
                    : 'color:red !important;cursor:default;'
                "
                dense
                color="black"
                >mdi-map-marker</v-icon
              >
            </v-col>
          </template>

          <template v-else>
            <!-- mobile -->

            <v-col :cols="'12'">
              <v-file-input
                style="
                  display: inline-block;
                 margin-right: 15px !important;
                 margin-left: 0!important;
                  position: relative;
                  top:5px;
                "
                dense
                hide-input
                class="my-0"
                prepend-icon="mdi-camera"
                accept="image/*"
                multiple
                @change="uploadImage($event, item.customer_id)"
              ></v-file-input>

              
              <v-icon
                class="my-1"
                dense
                @click="goPhone(item.customer_mobile)"
                
                style="color: blue !important; cursor: pointer; font-size: 22px;
                 margin-right: 15px !important;
                 margin-left: 15px !important;
                "
                >mdi-phone</v-icon
              >

              
              <v-icon
                dense
                @click="changePeriod(item.subscription_detail_id, item.customer_name)"
                style="color: red !important; cursor: pointer; font-size: 22px;margin: 0 15px;"
                >mdi-repeat</v-icon
              >

               <v-icon
              dense
              @click="goWatsapp(item.customer_mobile)"
              style="color: green !important; cursor: pointer; font-size: 22px;
               margin-right: 15px !important;
                 margin-left: 15px !important;
              "
              >mdi-whatsapp</v-icon
              >

              <v-icon
                @click="goToLink(item)"
                class="my-0"
                style="
                  display: inline-block;
                   margin-right: 15px !important;
                 margin-left: 15px !important;
                  font-size: 28px;
                  cursor: pointer;
                  position: relative;
                "
                :style="
                  item.link_map
                    ? 'color:green !important;cursor: pointer;'
                    : 'color:red !important;cursor:default;'
                "
                dense
                color="black"
                >mdi-map-marker</v-icon
              >

            </v-col>
          </template>
        </v-row>
      </template>
    </v-data-table>

    
   <div v-else>
      <div v-for="(item, dindex) in table.items2" :key="dindex" 
      style="padding:15px;border:3px solid #000;margin-top:0px;"
      :style="item.customer_is_new == 1? 'border: 5px solid  green;' : ''"
      >

       <div style="position: relative;" v-if="item.customer_is_new == 1">
  <div style="position: absolute; top: -15px; right: -15px; width: 0; height: 0; border-style: solid; border-width: 0px 50px 50px 0px;; border-color: transparent green transparent transparent;"></div>
  <div style="
    position: absolute;top: -13px;
    right: -15px;
    width: 28px;
    height: 18px;
    font-size: 13px;
    font-weight: bold;
    background-color: #008000; display: flex; align-items: center; justify-content: center;">
    <span style="color: white; font-weight: bold; font-style: italic;transform: rotate(45deg);">جديد</span>
  </div>
</div>


      
        <div style="display: flex; align-items: center; position: relative;right: 10px;">
        <h3 style="flex:1">
          <span v-if="item.driver_delivery == 0">
            <v-icon
              class="my-0 mr-2 ml-2"
              style="font-size: 28px; color: purple !important"
              dense
              >mdi-circle-outline</v-icon
            >
          </span>
          <span v-if="item.driver_delivery == 1" class="checkbox-marked">
            <v-icon class="my-0 mr-2 ml-2" style="font-size: 28px" dense
              >mdi-checkbox-marked-circle-outline</v-icon
            >
          </span>
          <span v-if="item.driver_delivery == 2" class="checkbox-unmarked">
            <v-icon
              class="my-0 mr-2 ml-2"
              style="font-size: 28px; color: red"
              dense
              >mdi-close-circle-outline</v-icon
            >
          </span>

            <span  @click="openGallery(item.customer_attachments, item.home_number)"
    :style="
      item.customer_attachments.length > 0
        ? 'color: blue; cursor: pointer; font-weight: bold;'
        : ''
    ">
              {{ item.id }}. {{ item.customer_name }}

    </span>
           
          <el-button
            style="padding: 2px;float: left;"
            v-if="details"
            :disabled="!details.deliver_btn || item.driver_delivery > 0"
            @click="openDeleteDelivar(item)"
            class="mx-2"
            type="danger"
          >
            <v-icon
              class="my-0"
              style="font-size: 28px; color: #fff !important"
              dense
              >mdi-close-circle-outline</v-icon
            >
          </el-button>
          

           <el-button
            v-if="details"
            style="padding: 2px;float: left;"
            :disabled="!details.deliver_btn || item.driver_delivery > 0"
            @click="
              detailsItem = item;
              delivarModal = true;
            "
            class="mx-2"
            type="success"
          >
            <v-icon
              class="my-0"
              style="font-size: 28px; color: #fff !important;"
              dense
              >mdi-checkbox-marked-circle-outline</v-icon
            >
          </el-button>

       
          
        </h3>
      </div>
       <div style="display: flex; align-items: center;">
        <h3 style="flex:1;margin: 0 50px;">
          {{ item.branch }} - {{ item.company }}
        </h3>
        
        <span style="float:left;margin: 10px 12px;font-weight:bold;font-size:16px;">
         {{ item.notes }}
        </span>
       </div>
        <div style="display: flex; align-items: center;">
           <v-file-input
           :style="dindex == 0? 'position:relative;top:2px':''"
            style="
              display: inline-block;
              margin: 0;
              margin-right: 15px !important;
              margin-left: 0 !important;
            "
            dense
            hide-input
            class="my-0"
            prepend-icon="mdi-camera"
            accept="image/*"
            multiple
            @change="uploadImage($event, item.customer_id)"
          ></v-file-input>

              
              <v-icon
                class="my-1"
                dense
                @click="goPhone(item.customer_mobile)"
                
                style="color: blue !important; cursor: pointer; font-size: 22px;
                 margin-right: 15px !important;
                 margin-left: 15px !important;
                "
                >mdi-phone</v-icon
              >

              
              <v-icon
                dense
                @click="changePeriod(item.subscription_detail_id, item.customer_name)"
                style="color: red !important; cursor: pointer; font-size: 22px;margin: 0 15px;"
                >mdi-repeat</v-icon
              >

               <v-icon
              dense
              @click="goWatsapp(item.customer_mobile)"
              style="color: green !important; cursor: pointer; font-size: 22px;
               margin-right: 15px !important;
                 margin-left: 15px !important;
              "
              >mdi-whatsapp</v-icon
              >

              <v-icon
                @click="goToLink(item)"
                class="my-0"
                style="
                  display: inline-block;
                   margin-right: 15px !important;
                 margin-left: 15px !important;
                  font-size: 28px;
                  cursor: pointer;
                  position: relative;
                "
                :style="
                  item.link_map
                    ? 'color:green !important;cursor: pointer;'
                    : 'color:red !important;cursor:default;'
                "
                dense
                color="black"
                >mdi-map-marker</v-icon
              >
       </div>
    </div>
   </div>

    <!-- End DataTable -->


     <h2 class="my-2" 
      :style="$vuetify.breakpoint.mdAndUp?'': 'text-align:center;'" 
     style="background:#8dff91;padding:15px;color:#000;border-radius:10px;">
      {{
        $route.name == "driverEvents2"
          ? "عرض المشتركين تم تسليم"
          : "عرض المشتركين بالنسبة للحي تم التسليم"
      }}
    </h2>

    <!-- Start DataTable -->
    <v-data-table
      v-if="$vuetify.breakpoint.mdAndUp"
      :headers="table.headers"
      :items="table.items3"
      :loading="table.loading"
      hide-default-footer
      :items-per-page="table.items3.length"
      class="elevation-1"
      :loading-text="$t('LoadData')"
    >
      <template v-slot:no-data>
        <v-alert class="mt-5 white-clr" color="third">
          {{ $t("NoData") }}
        </v-alert>
      </template>

      <template v-slot:item.customer_attachments="{ item }">
        <img
          @click="openGallery(item.customer_attachments, item.home_number)"
          v-if="item.customer_attachments"
          style="border-radius: 50%; margin: 0 5px; cursor: pointer"
          class="mr-2 mt-2"
          width="50"
          height="50"
          :src="
            item.customer_attachments.length > 0
              ? item.customer_attachments[item.customer_attachments.length - 1]
              : '/assets/no-img.png'
          "
          alt=""
        />
      </template>
      <template v-slot:item.branch="{ item }">
        <span>{{ item.branch }} - {{ item.company }}</span>
      </template>

      <template v-slot:item.customer_name="{ item }">
        <div style="display: flex; align-items: center;">
  <img  style="display: inline-block;" width="40" v-if="item.customer_is_new == 1" src="/assets/new_icon.png" alt="">
  <span
    @click="openGallery(item.customer_attachments, item.home_number)"
    :style="
      item.customer_attachments.length > 0
        ? 'color: blue; cursor: pointer; font-weight: bold;'
        : ''
    "
  >
    {{ item.customer_name }}
  </span>
</div>

      </template>

      <template v-slot:item.driver_delivery="{ item, index }">
        <div>
          <span v-if="item.driver_delivery == 0">
            <v-icon
              class="my-0 mr-2 ml-2"
              style="font-size: 28px; color: purple !important"
              dense
              >mdi-circle-outline</v-icon
            >
          </span>
          <span v-if="item.driver_delivery == 1" class="checkbox-marked">
            <v-icon class="my-0 mr-2 ml-2" style="font-size: 28px" dense
              >mdi-checkbox-marked-circle-outline</v-icon
            >
          </span>
          <span v-if="item.driver_delivery == 2" class="checkbox-unmarked">
            <v-icon
              class="my-0 mr-2 ml-2"
              style="font-size: 28px; color: red"
              dense
              >mdi-close-circle-outline</v-icon
            >
          </span>

          <el-button
            v-if="details"
            style="padding: 2px"
            :disabled="!details.deliver_btn || item.driver_delivery > 0"
            @click="
              detailsItem = item;
              delivarModal = true;
            "
            class="mx-2"
            type="success"
          >
            <v-icon
              class="my-0"
              style="font-size: 28px; color: #fff !important"
              dense
              >mdi-checkbox-marked-circle-outline</v-icon
            >
          </el-button>

          <el-button
            style="padding: 2px"
            v-if="details"
            :disabled="!details.deliver_btn || item.driver_delivery > 0"
            @click="openDeleteDelivar(item)"
            class="mx-2"
            type="danger"
          >
            <v-icon
              class="my-0"
              style="font-size: 28px; color: #fff !important"
              dense
              >mdi-close-circle-outline</v-icon
            >
          </el-button>
        </div>
      </template>

      <template v-slot:item.actions="{ item, index }">
        <v-row>
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-col :cols="'12'">
              <v-file-input
                style="display: inline-block !important; position: relative;margin: 0 15px;position:relative;top:5px;"
                dense
                hide-input
                prepend-icon="mdi-camera"
                accept="image/*"
                multiple
                @change="uploadImage($event, item.customer_id)"
              ></v-file-input>

              <v-icon
                dense
                @click="changePeriod(item.subscription_detail_id, item.customer_name)"
                style="color: red !important; cursor: pointer; font-size: 22px;margin: 0 15px;"
                >mdi-repeat</v-icon
              >

              <v-icon
              dense
              @click="goWatsapp(item.customer_mobile)"
              style="color: green !important; cursor: pointer; font-size: 24px;margin: 0 15px;"
              >mdi-whatsapp</v-icon
              >

              <v-icon
                @click="goToLink(item)"
                style="font-size: 28px; cursor: pointer; position: relative;margin: 0 15px;"
                :style="
                  item.link_map
                    ? 'color:green !important;cursor: pointer;'
                    : 'color:red !important;cursor:default;'
                "
                dense
                color="black"
                >mdi-map-marker</v-icon
              >
            </v-col>
          </template>

          <template v-else>
            <!-- mobile -->

            <v-col :cols="'12'">
              <v-file-input
                style="
                  display: inline-block;
                 margin-right: 15px !important;
                 margin-left: 0!important;
                  position: relative;
                  top:5px;
                "
                dense
                hide-input
                class="my-0"
                prepend-icon="mdi-camera"
                accept="image/*"
                multiple
                @change="uploadImage($event, item.customer_id)"
              ></v-file-input>

              
              <v-icon
                class="my-1"
                dense
                @click="goPhone(item.customer_mobile)"
                
                style="color: blue !important; cursor: pointer; font-size: 22px;
                 margin-right: 15px !important;
                 margin-left: 15px !important;
                "
                >mdi-phone</v-icon
              >

              
              <v-icon
                dense
                @click="changePeriod(item.subscription_detail_id, item.customer_name)"
                style="color: red !important; cursor: pointer; font-size: 22px;margin: 0 15px;"
                >mdi-repeat</v-icon
              >

               <v-icon
              dense
              @click="goWatsapp(item.customer_mobile)"
              style="color: green !important; cursor: pointer; font-size: 22px;
               margin-right: 15px !important;
                 margin-left: 15px !important;
              "
              >mdi-whatsapp</v-icon
              >

              <v-icon
                @click="goToLink(item)"
                class="my-0"
                style="
                  display: inline-block;
                   margin-right: 15px !important;
                 margin-left: 15px !important;
                  font-size: 28px;
                  cursor: pointer;
                  position: relative;
                "
                :style="
                  item.link_map
                    ? 'color:green !important;cursor: pointer;'
                    : 'color:red !important;cursor:default;'
                "
                dense
                color="black"
                >mdi-map-marker</v-icon
              >
              
            </v-col>

          </template>
        </v-row>
      </template>
    </v-data-table>

    
   <div v-else>
      <div v-for="(item, dindex) in table.items3" :key="dindex" 
      style="padding:15px;border:3px solid #000;margin-top:0px;"
      :style="item.customer_is_new == 1? 'border: 5px solid  green;' : ''"
      >

       <div style="position: relative;" v-if="item.customer_is_new == 1">
  <div style="position: absolute; top: -15px; right: -15px; width: 0; height: 0; border-style: solid; border-width: 0px 50px 50px 0px;; border-color: transparent green transparent transparent;"></div>
  <div style="
    position: absolute;top: -13px;
    right: -15px;
    width: 28px;
    height: 18px;
    font-size: 13px;
    font-weight: bold;
    background-color: #008000; display: flex; align-items: center; justify-content: center;">
    <span style="color: white; font-weight: bold; font-style: italic;transform: rotate(45deg);">جديد</span>
  </div>
</div>


      
        <div style="display: flex; align-items: center; position: relative;right: 10px;">
        <h3 style="flex:1">
          <span v-if="item.driver_delivery == 0">
            <v-icon
              class="my-0 mr-2 ml-2"
              style="font-size: 28px; color: purple !important"
              dense
              >mdi-circle-outline</v-icon
            >
          </span>
          <span v-if="item.driver_delivery == 1" class="checkbox-marked">
            <v-icon class="my-0 mr-2 ml-2" style="font-size: 28px" dense
              >mdi-checkbox-marked-circle-outline</v-icon
            >
          </span>
          <span v-if="item.driver_delivery == 2" class="checkbox-unmarked">
            <v-icon
              class="my-0 mr-2 ml-2"
              style="font-size: 28px; color: red"
              dense
              >mdi-close-circle-outline</v-icon
            >
          </span>

          <span  @click="openGallery(item.customer_attachments, item.home_number)"
    :style="
      item.customer_attachments.length > 0
        ? 'color: blue; cursor: pointer; font-weight: bold;'
        : ''
    ">
              {{ item.id }}. {{ item.customer_name }}

    </span>
           
          <el-button
            style="padding: 2px;float: left;"
            v-if="details"
            :disabled="!details.deliver_btn || item.driver_delivery > 0"
            @click="openDeleteDelivar(item)"
            class="mx-2"
            type="danger"
          >
            <v-icon
              class="my-0"
              style="font-size: 28px; color: #fff !important"
              dense
              >mdi-close-circle-outline</v-icon
            >
          </el-button>
          

           <el-button
            v-if="details"
            style="padding: 2px;float: left;"
            :disabled="!details.deliver_btn || item.driver_delivery > 0"
            @click="
              detailsItem = item;
              delivarModal = true;
            "
            class="mx-2"
            type="success"
          >
            <v-icon
              class="my-0"
              style="font-size: 28px; color: #fff !important;"
              dense
              >mdi-checkbox-marked-circle-outline</v-icon
            >
          </el-button>

       
          
        </h3>
      </div>
       <div style="display: flex; align-items: center;">
       
        <h3 style="flex:1;margin: 0 50px;">
          {{ item.branch }} - {{ item.company }}
        </h3>
        
        <span style="float:left;margin: 10px 12px;font-weight:bold;font-size:16px;">
         {{ item.notes }}
        </span>
       </div>

       <div>
        
       </div>
        <div style="display: flex; align-items: center;">
           <v-file-input
           :style="dindex == 0? 'position:relative;top:2px':''"
            style="
              display: inline-block;
              margin: 0;
              margin-right: 15px !important;
              margin-left: 0 !important;
            "
            dense
            hide-input
            class="my-0"
            prepend-icon="mdi-camera"
            accept="image/*"
            multiple
            @change="uploadImage($event, item.customer_id)"
          ></v-file-input>

              
              <v-icon
                class="my-1"
                dense
                @click="goPhone(item.customer_mobile)"
                
                style="color: blue !important; cursor: pointer; font-size: 22px;
                 margin-right: 15px !important;
                 margin-left: 15px !important;
                "
                >mdi-phone</v-icon
              >

              
              <v-icon
                dense
                @click="changePeriod(item.subscription_detail_id, item.customer_name)"
                style="color: red !important; cursor: pointer; font-size: 22px;margin: 0 15px;"
                >mdi-repeat</v-icon
              >

               <v-icon
              dense
              @click="goWatsapp(item.customer_mobile)"
              style="color: green !important; cursor: pointer; font-size: 22px;
               margin-right: 15px !important;
                 margin-left: 15px !important;
              "
              >mdi-whatsapp</v-icon
              >

              <v-icon
                @click="goToLink(item)"
                class="my-0"
                style="
                  display: inline-block;
                   margin-right: 15px !important;
                 margin-left: 15px !important;
                  font-size: 28px;
                  cursor: pointer;
                  position: relative;
                "
                :style="
                  item.link_map
                    ? 'color:green !important;cursor: pointer;'
                    : 'color:red !important;cursor:default;'
                "
                dense
                color="black"
                >mdi-map-marker</v-icon
              >
       </div>
    </div>
   </div>
    <!-- End DataTable -->

    <!-- <div class="mt-5" v-if="showMap">
      <AddGoogleMap ref="AddGoogleMap" style="width: 100%" :markers="markers" />
    </div> -->

    <!-- Start Pagination -->
    <!-- <div class="text-center pt-2">
      <v-pagination
        v-if="table.length"
        total-visible="5"
        v-model="table.page"
        :length="table.length"
        @input="changePage(table.page)"
      ></v-pagination>
    </div> -->

    <!-- End Pagination -->

    

     <v-dialog v-model="changePeriodModal" width="40rem">
      <v-card>
        <v-toolbar style="background: red !important" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            {{ $t("Confirm") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="py-0 pa-4 pt-4 black--text">
          <h3 class="clr-primary">هل أنت متأكد من تغيير الفترة للمشترك 

          <span style="color:blue;">{{periodCustomerName}}</span> ؟
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            small
            class="body-2 font-weight-bold"
            @click.native="changePeriodModal = false"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="success"
            class="body-2 font-weight-bold"
            outlined
            small
            @click.native="okChangePeriod()"
          >
            {{ $t("Ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="delivarModal" width="30rem">
      <v-card>
        <v-toolbar style="background: green !important" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            {{ $t("Confirm") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="py-0 pa-4 pt-4 black--text">
          <h3 class="clr-primary">هل أنت متأكد من عملية التسليم</h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            small
            class="body-2 font-weight-bold"
            @click.native="delivarModal = false"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="success"
            class="body-2 font-weight-bold"
            outlined
            small
            @click.native="delivar(1)"
          >
            {{ $t("Ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="notDelivarModal" width="30rem">
      <v-card>
        <v-toolbar style="background: red !important" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            {{ $t("Confirm") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="py-0 pa-4 pt-4 black--text">
          <h3 class="clr-primary">
            هل أنت متأكد من عملية إلغاء التسليم ؟
            <v-row class="my-2">
              <v-col
                class="py-0"
                :cols="$vuetify.breakpoint.mdAndUp ? '12' : '12'"
              >
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.note_delivary`),
                  }"
                >
                  <v-textarea
                    rows="4"
                    placeholder="سبب عدم التسليم"
                    class="d-block my-2"
                    name="note_delivary"
                    data-vv-scope="addEditValidation"
                    v-validate="'required'"
                    :data-vv-as="'سبب عدم التسليم'"
                    hide-details
                    dense
                    outlined
                    v-model="note_delivary"
                  >
                  </v-textarea>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.note_delivary`)"
                >
                  {{ errors.first(`addEditValidation.note_delivary`) }}
                </div>
              </v-col>
            </v-row>
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            small
            class="body-2 font-weight-bold"
            @click.native="notDelivarModal = false"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="success"
            class="body-2 font-weight-bold"
            outlined
            small
            @click.native="notDelivar(2)"
          >
            {{ $t("Ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="notesModal" width="40rem">
      <v-card v-if="itemNotes">
        <v-toolbar style="background: green !important" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            <h4>طريقة التسليم / {{ itemNotes.customer_name }}</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-5 pb-5 black--text">
          <v-row class="my-2">
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '12' : '12'">
              <h3 style="color: black">
                طريقة التسليم :
                <span style="color: red">{{ itemNotes.notes }}</span>
              </h3>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="linkMapModal" width="30rem">
      <v-card>
        <v-toolbar style="background: red !important" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            إضافة رابط موقع
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="py-0 pa-4 pt-4 black--text">
          <v-row class="my-2">
            <v-col
              class="py-0"
              :cols="$vuetify.breakpoint.mdAndUp ? '12' : '12'"
            >
              <div
                :class="{
                  'has-error': errors.has(`addEditValidation.link_map`),
                }"
              >
                <v-text-field
                  placeholder="رابط الموقع"
                  class="d-block my-2"
                  name="link_map"
                  data-vv-scope="addEditValidation"
                  v-validate="'required'"
                  :data-vv-as="'رابط الموقع'"
                  hide-details
                  dense
                  outlined
                  style="direction: ltr"
                  v-model="link_map"
                >
                </v-text-field>
              </div>

              <div
                class="help-block"
                v-if="errors.has(`addEditValidation.link_map`)"
              >
                {{ errors.first(`addEditValidation.link_map`) }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            small
            class="body-2 font-weight-bold"
            @click.native="closeLinkMap"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="success"
            class="body-2 font-weight-bold"
            outlined
            small
            @click.native="saveLinkMap"
          >
            {{ $t("Ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="galaryModal" width="60rem">
      <v-card>
        <v-toolbar
          style="background: brown !important; color: #000 !important"
          dense
          flat
        >
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            معرض الصور
          </v-toolbar-title>
          <span
            @click="
              galaryModal = false;
              galaryIndex = 0;
            "
            style="
              color: #fff;
              cursor: pointer;
              position: absolute;
              top: 5px;
              left: 10px;
              font-size: 22px;
            "
            >x</span
          >
        </v-toolbar>
        <v-card-text class="py-0 pa-4 pt-4 black--text">
          <img
            style="width: 90%; height: 300px; display: block; margin: 0 auto"
            :src="ourconfig.hostUrl+galaryImages[galaryIndex]"
            alt=""
          />

          <h2 class="mx-5 my-5 text-center">رقم المنزل : <span>{{homeNum}}</span></h2>
        </v-card-text>
        <v-card-actions>
          <div style="display: block; margin: 0 auto">
            <v-btn
              v-if="galaryIndex < galaryImages.length - 1"
              small
              style="background: #000 !important"
              class="body-2 font-weight-bold mx-2"
              @click.native="galaryIndex = galaryIndex + 1"
            >
              <v-icon
                class="my-0 mr-2 mx-2"
                style="font-size: 28px; color: #fff"
                dense
                >mdi-arrow-right</v-icon
              >
            </v-btn>
            <v-btn
              v-if="galaryIndex > 0"
              small
              style="background: #000 !important"
              class="body-2 font-weight-bold"
              @click.native="galaryIndex = galaryIndex - 1"
            >
              <v-icon
                class="my-0 mr-2 ml-2"
                style="font-size: 28px; color: #fff"
                dense
                >mdi-arrow-left</v-icon
              >
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <BlockUI v-if="loadClicked" :message="msg">
      <div class="sk-wave">
        <div class="sk-rect sk-rect1"></div>
        <div class="sk-rect sk-rect2"></div>
        <div class="sk-rect sk-rect3"></div>
        <div class="sk-rect sk-rect4"></div>
        <div class="sk-rect sk-rect5"></div>
      </div>
    </BlockUI>
  </div>
</template>




<script>
import config from '@/config';
import moment from "moment";
// import AddGoogleMap from "./AddGoogleMap.vue";
export default {
  name: "eventsIndex",
  components: {
    // AddGoogleMap,
  },
  data() {
    return {
      ourconfig:config,
      linkMapModal: false,
      notesModal: false,
      itemNotes: null,
      link_map: null,
      note_delivary: null,
      detailsItem: null,
      delivarModal: false,
      changePeriodModal: false,
      notDelivarModal: false,
      loadClicked: false,
      msg: "برجاء الانتظار لحين اكتمال العملية",
      filters: {
        neighborhood_id: null,
      },
      branchesList: [],
      details: null,
      markers: [],
      showMap: false,
      filtersItems: [
        { text: this.$t("All"), value: null },
        { text: this.$t("Active"), value: 1 },
        { text: this.$t("InActive"), value: 0 },
      ],
      config: {
        crudApi: "eventSubscribtion",
        filterWidth: "20rem",
        deleteModalWidth: "30rem",
        modalFormWidth: "50rem",
      },
      table: {
        page: 1,
        length: null,
        itemsPerPage: 10,
        loading: false,
        items: [],
        items2: [],
        items3: [],
        itemExport: {
          "#": "id",
          "الاسم عربي": {
            field: "name.ar",
            callback: (item) => {
              if (item) return item;
            },
          },
          "الاسم إنجليزي": {
            field: "name.en",
            callback: (item) => {
              if (item) return item;
            },
          },
          الحالة: {
            field: "status",
            callback: (value) => {
              if (value) return "فعال";
              else return "غير فعال";
            },
          },
        },
        headers: [
          { text: "#", value: "id" },
          {
            text: this.$t("Name"),
            value: "customer_name",
          },
          {
            text: this.$t("DelivaryMethod"),
            value: "notes",
          },
          {
            text: "المنطقة - الشركة",
            value: "branch",
          },
          {
            text: "تسليم",
            value: "driver_delivery",
          },
          {
            text: this.$t("Actions"),
            value: "actions",
            sortable: false,
            align: "center",
          },
        ],
      },
      filters: {
        name: null,
        status: null,
      },
      formData: {
        id: 0,
        sort: null,
      },
      drawer: false,
      dialog: false,
      dialogDelete: false,
      galaryImages: [],
      galaryIndex: 0,
      galaryModal: false,
      itemLinkMap: null,
      periodCustomerID: null,
      periodCustomerName: null,
      homeNum: '',
    };
  },
  methods: {
     openGallery(images, homeNum) {
      if (images.length > 0) {
        this.galaryImages = images;
        this.galaryIndex = 0;
        this.galaryModal = true;
        this.homeNum= homeNum;
      }
    },
    okChangePeriod() {
       this.$store
          .dispatch(`driverDetails/changePeriod`, this.periodCustomerID)
          .then((res) => {
            this.notifySuccess("Change", res);
            this.changePeriodModal = false;
             this.periodCustomerID= null;
            this.periodCustomerName= null;
            if (this.$route.name == "driverEvents") {
              this.getDataFromApi();
            } else if (this.$route.name == "driverEvents2") {
              this.getDataFromApi2();
            }
      });
    },
   changePeriod(id, name) {
      this.changePeriodModal = true;
      this.periodCustomerID= id;
      this.periodCustomerName= name;
    },
   goWatsapp(phoneNumber) {
      let newPhone = phoneNumber;
      if (!newPhone.includes('+966')) newPhone = '+966' + phoneNumber;
      newPhone = newPhone.replace(/\s/g, '').replace('+9660','+966');
      if (newPhone.length === 13) {
        window.open(`https://wa.me/${newPhone}`);
      } else {
        this.notifyCustomError('Error', 'Wrong mobile');
      }
    },

    goPhone(phoneNumber) {
      let newPhone = phoneNumber;
      if (!newPhone.includes('+966')) newPhone = '+966' + phoneNumber;
      newPhone = newPhone.replace('+9660','+966').replace(/\s/g, '');
      if (newPhone.length === 13) {
       window.location.href = `tel:${newPhone}`;
      } else {
        this.notifyCustomError('Error', 'Wrong mobile');
      }
    },
    openModalShowNotes(item) {
      this.notesModal = true;
      this.itemNotes = item;
    },
    closeLinkMap() {
      this.linkMapModal = false;
    },
    saveLinkMap(item) {
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");

        let sendData = {
          id: this.itemLinkMap.subscription_id,
          link_map: this.link_map,
        };
        this.$store
          .dispatch(`driverDetails/uploadLinkMap`, sendData)
          .then((res) => {
            this.notifySuccess("File", res);
            this.linkMapModal = false;
            if (this.$route.name == "driverEvents") {
              this.getDataFromApi();
            } else if (this.$route.name == "driverEvents2") {
              this.getDataFromApi2();
            }
          });
      });
    },
    openDeleteDelivar(item) {
      this.note_delivary = null;
      this.$validator.reset();
      this.detailsItem = item;
      this.notDelivarModal = true;
    },
    uploadImage(files, id) {
      this.tobase64Handler(files, id);
    },

    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    async tobase64Handler(files, id) {
      // const filePathsPromises = [];
      // files.forEach((file) => {
      //   filePathsPromises.push(this.toBase64(file));
      // });
      // const filePaths = await Promise.all(filePathsPromises);
      // const mappedFiles = filePaths.map((base64File) => base64File);

      let sendData = {
        files: files,
        id: id,
      };

      this.$store
        .dispatch(`driverDetails/uploadImages`, sendData)
        .then((res) => {
          this.notifySuccess("Upload", "Upload Successfully");
          if (this.$route.name == "driverEvents") {
            this.getDataFromApi();
          } else if (this.$route.name == "driverEvents2") {
            this.getDataFromApi2();
          }
        });
    },
    //    handleFileSelect(files,id) {
    //     let images= [];
    //     files.forEach(f => {
    //       const toBase64 = file => new Promise((resolve, reject) => {
    //       const reader = new FileReader();
    //       reader.readAsDataURL(file);
    //       reader.onload = () => resolve(reader.result);
    //       reader.onerror = error => reject(error);
    //       });
    //     });

    //     console.log(images);

    //  },
    notDelivar(val) {
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");
        let sendData = {
          subscription_detail_id: this.detailsItem.subscription_detail_id,
          driver_delivery: val,
          note_delivary: this.note_delivary,
           period_id: this.$route.params.id2,
           date: localStorage.getItem("event_date"),
        };
        this.$store
          .dispatch(`driverDetails/update_driver_delivery`, sendData)
          .then((res) => {
            this.notifySuccess(`UpdateStatus`, res);
            if (this.delivarModal) this.delivarModal = false;
            if (this.notDelivarModal) this.notDelivarModal = false;

            if (this.$route.name == "driverEvents") {
              this.detailsItem = null;
              this.getDataFromApi();
            } else if (this.$route.name == "driverEvents2") {
              this.detailsItem = null;
              this.getDataFromApi2();
            }
          });
      });
    },

    delivar(val) {
      let sendData = {
        subscription_detail_id: this.detailsItem.subscription_detail_id,
        driver_delivery: val,
        period_id: this.$route.params.id2,
        date: localStorage.getItem("event_date"),
      };
      this.$store
        .dispatch(`driverDetails/update_driver_delivery`, sendData)
        .then((res) => {
          this.notifySuccess(`UpdateStatus`, res);
          if (this.delivarModal) this.delivarModal = false;
          if (this.notDelivarModal) this.notDelivarModal = false;

          if (this.$route.name == "driverEvents") {
            this.detailsItem = null;
            this.getDataFromApi();
          } else if (this.$route.name == "driverEvents2") {
            this.detailsItem = null;
            this.getDataFromApi2();
          }
        });
    },
    filterData() {},
    sort(item) {
      let send = {
        subscription_id: item.subscription_id,
        order: item.order,
        subscriptions: this.table.items,
      };

      this.$store
        .dispatch(`eventSubscribtion/updateSubscriptionOrder`, send)
        .then((res) => {
          if (this.$route.name == "driverEvents") {
            this.getDataFromApi();
          } else if (this.$route.name == "driverEvents2") {
            this.getDataFromApi2();
          }
        });
    },
    sortUp(item, index) {
      this.loadClicked = true;
      let send = {
        id: item.subscription_id,
        subscription_id: this.table.items[index - 1].subscription_id,
      };

      this.$store
        .dispatch(`eventSubscribtion/updateSubscriptionOrder2`, send)
        .then((res) => {
          if (this.$route.name == "driverEvents") {
            this.getDataFromApi();
          } else if (this.$route.name == "driverEvents2") {
            this.getDataFromApi2();
          }
        });
    },
    sortDown(item, index) {
      this.loadClicked = true;
      let send = {
        id: item.subscription_id,
        subscription_id: this.table.items[index + 1].subscription_id,
      };

      this.$store
        .dispatch(`eventSubscribtion/updateSubscriptionOrder2`, send)
        .then((res) => {
          if (this.$route.name == "driverEvents") {
            this.getDataFromApi();
          } else if (this.$route.name == "driverEvents2") {
            this.getDataFromApi2();
          }
        });
    },
    goToLink(item) {
      this.itemLinkMap = item;
      if (item.link_map) window.open(item.link_map);
      else {
        this.note_delivary = null;
        this.$validator.reset();
        this.linkMapModal = true;
      }
    },
    updateLink(item) {
      this.itemLinkMap = item;
      this.link_map = item.link_map;
      this.$validator.reset();
      this.linkMapModal = true;
    },
    goTo(routeName, item) {
      localStorage.setItem("event_branch_id", this.$route.params.id);
      localStorage.setItem("event_period_id", this.$route.params.id2);
      this.$router.push({
        name: routeName,
        params: { id: item.subscription_id, id2: item.subscription_detail_id },
      });
    },
    goMap(title, lat, lng) {
      this.markers = [];
      setTimeout(() => {
        this.showMap = true;
        this.markers.push({
          title: title,
          latitude: lat,
          longitude: lng,
        });
        this.$refs.AddGoogleMap.initMap();
      }, 100);
    },
    loadPDF() {
      localStorage.setItem("filterReport", JSON.stringify(this.filters));
      window.open(`/reports/${this.config.crudApi}`);
    },
    clearFilters() {
      for (let key in this.filters) {
        this.filters[key] = null;
      }
      if (this.$route.name == "driverEvents") {
        this.getDataFromApi();
      } else if (this.$route.name == "driverEvents2") {
        this.getDataFromApi2();
      }
    },
    clearData() {
      this.formData = {
        id: null,
        sort: null,
      };
      this.$validator.reset();
    },
    deleteItemConfirm() {
      this.deleteData(
        `${this.config.crudApi}/removeData`,
        this.formData.id
      ).then(() => {
        if (this.$route.name == "driverEvents") {
          this.getDataFromApi();
        } else if (this.$route.name == "driverEvents2") {
          this.getDataFromApi2();
        }
        this.dialogDelete = false;
      });
    },
    updateStatus(item) {
      item.isLoading = true;
      this.updateStatusData(`${this.config.crudApi}/updateStatus`, item.id)
        .then(() => {
          if (this.$route.name == "driverEvents") {
            this.getDataFromApi();
          } else if (this.$route.name == "driverEvents2") {
            this.getDataFromApi2();
          }
        })
        .finally(() => {
          item.isLoading = false;
        });
    },
    closeModal() {
      this.formData.id = 0;
      this.dialog = false;
      this.$validator.reset();
    },
    save() {
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");
        this.saveUpdateData(
          `${this.config.crudApi}/saveData`,
          `${this.config.crudApi}/updateData`,
          this.formData
        ).then(() => {
          if (this.$route.name == "driverEvents") {
            this.getDataFromApi();
          } else if (this.$route.name == "driverEvents2") {
            this.getDataFromApi2();
          }
          this.closeModal();
        });
      });
    },
    getDataFromApi() {
      let options = {
        page: this.page,
        branch_id: this.$route.params.id,
        period_id: this.$route.params.id2,
        date: localStorage.getItem("event_date"),
      };
      this.table.loading = true;
      this.getFrontEndData(`${this.config.crudApi}/getData`, options)
        .then((res) => {
          res.subscriptions.map((x) => {
            x.order = "";
            return x;
          });
           res.subscriptions_not_deliver.map((x) => {
            x.order = "";
            return x;
          });
          res.subscriptions_deliver.map((x) => {
            x.order = "";
            return x;
          });
          this.table.items = res.subscriptions;
           this.table.items2= res.subscriptions_not_deliver;
          this.table.items3= res.subscriptions_deliver;
          this.details = {
            count_subscription: res.count_subscription,
            deliver: res.deliver,
            not_deliver: res.not_deliver,
            deliver_btn: res.deliver_btn,
          };
          this.loadClicked = false;
          // this.table.length = Math.ceil(res.pagination.total / res.pagination.per_page);
        })
        .finally(() => {
          this.table.loading = false;
          this.loadClicked = false;
        });
    },
    getDataFromApi2() {
      let options = {
        page: this.page,
        period_id: this.$route.params.id2,
        city_id: this.$route.params.id,
        date: localStorage.getItem("event_date"),
      };
      this.table.loading = true;
      this.getFrontEndData(`${this.config.crudApi}/getData2`, options)
        .then((res) => {
          res.subscriptions.map((x) => {
            x.order = "";
            return x;
          });

           res.subscriptions_not_deliver.map((x) => {
            x.order = "";
            return x;
          });
          res.subscriptions_deliver.map((x) => {
            x.order = "";
            return x;
          });

          this.details = {
            count_subscription: res.count_subscription,
            deliver: res.deliver,
            not_deliver: res.not_deliver,
            deliver_btn: res.deliver_btn,
          };
          this.table.items = res.subscriptions;
          this.table.items2= res.subscriptions_not_deliver;
          this.table.items3= res.subscriptions_deliver;

          this.loadClicked = false;
        })
        .finally(() => {
          this.table.loading = false;
          this.loadClicked = false;
        });
    },
    // changePage(page) {
    //   this.page= page;
    //   this.getDataFromApi();
    // },
  },
  mounted() {
    let me = this;
    window.addEventListener("keydown", function (e) {
      if (me) {
        if (e.code == "ArrowRight" && me.galaryModal == true) {
          if (me.galaryIndex < me.galaryImages.length - 1)
            me.galaryIndex = me.galaryIndex + 1;
        } else if (e.code == "ArrowLeft" && me.galaryModal == true) {
          if (me.galaryIndex > 0) me.galaryIndex = me.galaryIndex - 1;
        } else if (e.code == "Esc" && me.galaryModal == true) {
          me.galaryModal = false;
          me.galaryIndex = 0;
        }
      }
    });

    if (localStorage.getItem("branchesList")) {
      this.branchesList = JSON.parse(localStorage.getItem("branchesList"));
    }
    if (this.$route.name == "driverEvents") {
      this.getDataFromApi();
    } else if (this.$route.name == "driverEvents2") {
      this.getDataFromApi2();
    }
  },
};
</script>


<style>
.checkbox-marked .v-icon.v-icon {
  color: green !important;
}
.checkbox-unmarked .v-icon.v-icon {
  color: red !important;
}
</style>